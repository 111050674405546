/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StateSelectDialog.js 125 2024-04-23 15:40:24Z core $
 */


import	{ useState	}	from	'react' ;
import	Modal			from	'react-modal' ;
import	StateListParts	from	'../parts/StateListParts' ;


/*
 *	- props.stateData
 *	- props.onSelectedState( id, name )
 */
const	StateSelectDialog	= ( props )	=> {

	const	[ modalIsOpen,	setIsOpen		]	= useState( false ) ;
	const	[ stateData,	setStateData	]	= useState( props.stateData ) ;
	const	[ initialized,	setInitialized	]	= useState( false ) ;

	Modal.setAppElement( '#root' ) ;

	if( !initialized && props.stateData.id !== undefined ) {
		if( props.stateData.id !== null ) {
			setStateData( props.stateData ) ;
			setInitialized( true ) ;
		}
	}

	const	onSelectedState	= ( id, name )	=> {
		setIsOpen( false ) ;
		if( typeof( props.onSelectedState ) === 'function' )
			props.onSelectedState( id, name ) ;
		setStateData({
			id		: id,
			name	: name,
		}) ;
	} ;


	return(
	  <div>
		<input
		  type		= 'text'
		  className	= 'form-control'
		  value		= { stateData.name }
		  name		= 'statenm'
		  readOnly	= { true }
		  onClick	= {() => setIsOpen( true )}
		/>
		<Modal isOpen={modalIsOpen}
		  style	= {{
			overlay	: {
			  zIndex	: 100,
			},
		  }}
		>
		  <h2>state selector</h2>
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		  <StateListParts
			id				={ stateData.id }
			onSelectedState	={ onSelectedState }
		  />
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		</Modal>
	  </div>
	) ;
} ;


export	default	StateSelectDialog ;
