/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: FailureNewPage.js 159 2024-05-10 06:54:38Z core $
 */


import	axios						from	'axios' ;
import	{ useParams	}				from	'react-router-dom' ;
import	FailureEditParts			from	'../parts/FailureEditParts' ;

import	Footer						from	'../parts/Footer' ;
import	Header						from	'../parts/Header' ;


/*
 *	@param	params.storageid	(int)
 */
const	FailureNewPage	= ()	=> {

	let	params	= useParams() ;

	return(
		<>
			<Header />
			<h1>New Failure</h1>
			<FailureEditParts
				storageid={params.storageid}
			/>
			<Footer />
		</>
	) ;
} ;


export	default	FailureNewPage ;
