/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: FailureService.js 133 2024-05-06 16:30:16Z core $
 */


import	axios	from	'axios' ;


const	url		= "/failures"


const	create	= ( data )	=> {
	return( axios.post( url, data )) ;
} ;


const	getAll	= ()	=> {
	return( axios.get( url )) ;
} ;


const	get		= ( id )	=> {
	console.log( 'baseURL=' + axios.defaults.baseURL )
	console.log( 'axios.get( ' + url + '/' + id + ' )' ) ;
	return( axios.get( url + '/' + id )) ;
} ;


const	update	= ( id, data )	=> {
	return( axios.put( url +  '/' +id, data )) ;
} ;


const	remove	= ( id )	=> {
	return( axios.delete( url +  '/' +id )) ;
} ;


const	findByStorageId	= ( storageid )	=> {
	return( axios.get( url + '?storageid=' + storageid )) ;
} ;


const	findByText	= ( t )		=> {
	return( axios.get( url + '?q=' + t )) ;
} ;


const	FailureService	= {
	url,
	create,
	getAll,
	get,
	update,
	remove,
	findByStorageId,
	findByText,
} ;


export  default	FailureService ;

