/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: Badge.js 132 2024-05-06 13:18:16Z core $
 */


import	React								from 'react' ;
import	{ Badge as BootstrapBadge }			from 'react-bootstrap' ;


/*
 *	see also: https://react-bootstrap.netlify.app/docs/components/badge/
 */
const	Badge	= ( props )	=> {

	const	{
		bg,
		pill,
		children,
		...otherProps
	}	= props ;


	return(
		<BootstrapBadge bg={bg} pill={pill} {...otherProps}>
			{children}
		</BootstrapBadge>
	) ;
} ;


export	default	Badge ;
