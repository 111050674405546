/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: WriteMethodListPage.js 148 2024-05-08 06:19:43Z core $
 */


import	{ Link, Navigate		}	from	"react-router-dom" ;

import	WriteMethodListParts		from	'../parts/WriteMethodListParts' ;
import	Footer						from	"../parts/Footer" ;
import	Header						from	"../parts/Header" ;

import	'../storageledger.css' ;


const	WriteMethodListPage	= () => {

	return(
		<>
		  <Header />
		  <h1>WriteMethod List</h1>
		  <p><Link to={'/writemethods/new'}>new writemethod</Link></p>
		  <WriteMethodListParts />
		  <Footer />
		</>
	) ;
} ;


export	default	WriteMethodListPage ;
