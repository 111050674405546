/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: WriteMethodSelectDialog.js 148 2024-05-08 06:19:43Z core $
 */


import	{ useState	}			from	'react' ;
import	Modal					from	'react-modal' ;
import	WriteMethodListParts	from	'../parts/WriteMethodListParts' ;


/*
 *	- props.writemethodData
 *	- props.onSelectedWriteMethod( id, name )
 */
const	WriteMethodSelectDialog	= ( props )	=> {

	const	[ modalIsOpen,		setIsOpen			]	= useState( false ) ;
	const	[ writemethodData,	setWriteMethodData	]	= useState( props.writemethodData ) ;
	const	[ initialized,	setInitialized	]	= useState( false ) ;

	Modal.setAppElement( '#root' ) ;

	if( !initialized && props.writemethodData.id !== undefined ) {
		if( props.writemethodData.id !== null ) {
			setWriteMethodData( props.writemethodData ) ;
			setInitialized( true ) ;
		}
	}

	const	onSelectedWriteMethod	= ( id, name )	=> {
		setIsOpen( false ) ;
		if( typeof( props.onSelectedWriteMethod ) === 'function' )
			props.onSelectedWriteMethod( id, name ) ;
		setWriteMethodData({
			id		: id,
			name	: name,
		}) ;
	} ;


	return(
	  <div>
		<input
		  type		= 'text'
		  className	= 'form-control'
		  value		= { writemethodData.name }
		  name		= 'writemethodname'
		  readOnly	= { true }
		  onClick	= {() => setIsOpen( true )}
		/>
		<Modal isOpen={modalIsOpen}
		  style	= {{
			overlay	: {
			  zIndex	: 100,
			},
		  }}
		>
		  <h2>writemethod selector</h2>
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		  <WriteMethodListParts
			id				={ writemethodData.id }
			onSelectedWriteMethod	={ onSelectedWriteMethod }
		  />
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		</Modal>
	  </div>
	) ;
} ;


export	default	WriteMethodSelectDialog ;
