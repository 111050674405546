/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: App.js 152 2024-05-09 06:54:21Z core $
 */


import	{ useEffect	}						from 'react' ;
import	{ BrowserRouter, Routes, Route
										}	from 'react-router-dom' ;
import	{ OidcProvider, OidcSecure, useOidcIdToken
										}	from '@axa-fr/react-oidc' ;

import	{ oidcGoogleConfig	}			from './constants' ;
import	{ axiosSetIdToken	}			from './plugins/axios' ;

import	FailureEditPage					from './pages/FailureEditPage' ;
import	FailureListPage					from './pages/FailureListPage' ;
import	FailureNewPage					from './pages/FailureNewPage' ;
import	InterfaceEditPage				from './pages/InterfaceEditPage' ;
import	InterfaceListPage				from './pages/InterfaceListPage' ;
import	InterfaceNewPage				from './pages/InterfaceNewPage' ;
import	MakerEditPage					from './pages/MakerEditPage' ;
import	MakerListPage					from './pages/MakerListPage' ;
import	MakerNewPage					from './pages/MakerNewPage' ;
import	ModelEditPage					from './pages/ModelEditPage' ;
import	ModelListPage					from './pages/ModelListPage' ;
import	ModelNewPage					from './pages/ModelNewPage' ;
import	NotFoundPage					from './pages/NotFoundPage' ;
import	ProductEditPage					from './pages/ProductEditPage' ;
import	ProductListPage					from './pages/ProductListPage' ;
import	ProductNewPage					from './pages/ProductNewPage' ;
import	StateEditPage					from './pages/StateEditPage' ;
import	StateListPage					from './pages/StateListPage' ;
import	StateNewPage					from './pages/StateNewPage' ;
import	StorageEditPage					from './pages/StorageEditPage' ;
import	StorageListPage					from './pages/StorageListPage' ;
import	StorageNewPage					from './pages/StorageNewPage' ;
import	TestPage						from './pages/TestPage' ;
import	WriteMethodEditPage				from './pages/WriteMethodEditPage' ;
import	WriteMethodListPage				from './pages/WriteMethodListPage' ;
import	WriteMethodNewPage				from './pages/WriteMethodNewPage' ;

import	"bootstrap/dist/css/bootstrap.min.css" ;
import	"@fortawesome/fontawesome-free/css/all.css" ;
import	"@fortawesome/fontawesome-free/js/all.js" ;

import	'./App.css' ;
import	'./storageledger.css' ;


/*
 *	認証成功時に呼び出されるコールバックなのだが、
 *	useOidcIdToken() で idtoken ではなく null object を返してくる。
 *	コードは残しておくが使えない。
 *	仕方がないので各ページで axiosSetIdToken() を呼び出すようにする。
 */
/*
const	OnOidcSucceeded	= () => {
	var	toString	= Object.prototype.toString ;
	const	{ idToken	}				= useOidcIdToken() ;
	console.log( 'OnOidcSucceeded():33: toString.call( idToken )=' + toString.call( idToken )) ;
	useEffect(() => {
		console.log( 'OnOidcSucceeded():36: toString.call( idToken )=' + toString.call( idToken )) ;
		if( idToken || 1 ) {
			console.log( 'OnOidcSucceeded():32: JSON.stringify( idToken )=' + JSON.stringify( idToken )) ;
			axiosSetIdToken( JSON.stringify( idToken )) ;
		}
	}, [idToken] ) ;

	return( <div>OnOidcSucceeded</div> ) ;
}
*/


function	App() {
	return(
	  <div>
		<OidcProvider configuration={oidcGoogleConfig}
//		  callbackSuccessComponent={OnOidcSucceeded}
		>
		  <OidcSecure>

			<BrowserRouter basename="/">
			  <Routes>
				<Route exact path={ '/' }	element={< StorageListPage	/>}	/>
				<Route exact path={ '/failures' }
											element={< FailureListPage	/>}	/>
				<Route exact path={ '/failures/new/:storageid' }
											element={< FailureNewPage	/>}	/>
				<Route exact path={ '/failures/:id' }
											element={< FailureEditPage	/>}	/>
				<Route exact path={ '/interfaces' }
										element={< InterfaceListPage	/>}	/>
				<Route exact path={ '/interfaces/:id' }
										element={< InterfaceEditPage	/>}	/>
				<Route exact path={ '/interfaces/new' }
										element={< InterfaceNewPage		/>}	/>
				<Route exact path={ '/makers' }
											element={< MakerListPage	/>}	/>
				<Route exact path={ '/makers/new' }
											element={< MakerNewPage		/>}	/>
				<Route exact path={ '/makers/:id' }
											element={< MakerEditPage	/>}	/>
				<Route exact path={ '/models' }
											element={< ModelListPage	/>}	/>
				<Route exact path={ '/models/new' }
											element={< ModelNewPage		/>}	/>
				<Route exact path={ '/models/:id' }
											element={< ModelEditPage	/>}	/>
				<Route exact path={ '/products' }
											element={< ProductListPage	/>}	/>
				<Route exact path={ '/products/new' }
											element={< ProductNewPage	/>}	/>
				<Route exact path={ '/products/:id' }
											element={< ProductEditPage	/>}	/>
				<Route exact path={ '/states' }
											element={< StateListPage	/>}	/>
				<Route exact path={ '/states/new' }
											element={< StateNewPage		/>}	/>
				<Route exact path={ '/states/:id' }
											element={< StateEditPage	/>}	/>
				<Route exact path={ '/storages' }
											element={< StorageListPage	/>}	/>
				<Route exact path={ '/storages/new' }
											element={< StorageNewPage	/>}	/>
				<Route exact path={ '/storages/:id' }
											element={< StorageEditPage	/>}	/>
				<Route exact path={ '/writemethods' }
										element={< WriteMethodListPage	/>}	/>
				<Route exact path={ '/writemethods/new' }
										element={< WriteMethodNewPage	/>}	/>
				<Route exact path={ '/writemethods/:id' }
										element={< WriteMethodEditPage	/>}	/>

				<Route exact path={ '/test' }
											element={< TestPage			/>}	/>
				<Route exact path={ `/*` }	element={< NotFoundPage		/>} />
		 	  </Routes>
			</BrowserRouter>

		  </OidcSecure>
		</OidcProvider>
	  </div>
	) ;
} ;


export default App;

