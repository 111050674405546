/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: constants.js 49 2024-03-20 11:36:52Z core $
 */


/**
 *	@brief	openid connect configuration for google auth.
 *	@see	App.App().
 */
export	const	oidcGoogleConfig	= {
	client_id:		'926226989959-ipl5paltblhd6bncs6p41f8phmjvaam1.apps.googleusercontent.com',
	redirect_uri:	window.location.origin + '/authentication/callback',
	silent_redirect_uri:
					window.location.origin + '/authentication/silent-callback',
	scope:			'openid profile email',
					// offline_access scope allow your client to retrieve the
					// refresh_token
	authority:		'https://accounts.google.com/',
	service_worker_relative_url:
					"/OidcServiceWorker.js",
					// just comment that line to disable service worker mode
	service_worker_only:	false,
	token_request_extras:	{
		client_secret: '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',
	},
	demonstrating_proof_of_possession:	false, 
    monitor_session: false,
} ;
