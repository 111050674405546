/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: TestService.js 73 2024-04-20 07:40:03Z core $
 */


import	axios	from	'axios' ;


const	url		= "/tests"


const	getBearerToken	= ()	=> {
	return( axios.get( url + "/bearertoken" )) ;
} ;


const	getAccessToken	= ()	=> {
	return( axios.get( url + "/accesstoken" )) ;
} ;

const	getUserid	= ()	=> {
	return( axios.get( url + "/userid" )) ;
} ;


const	TestService	= {
	getBearerToken,
	getAccessToken,
	getUserid,
} ;


export  default	TestService ;

