/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: ProductEditPage.js 89 2024-04-21 10:01:32Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	ProductEditParts				from	'../parts/ProductEditParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		product id.
 */
const	ProductEditPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>Edit Product</h1>
			<ProductEditParts
				id={params.id}
			/>
			<Footer />
		</>
	) ;
} ;

export	default	ProductEditPage ;
