/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: DateCell.js 155 2024-05-09 08:04:37Z core $
 */


import	{ format		}	from	"date-fns" ;


/*
 *	@param	t		epoch time.
 */
const	DateCell	= ( t )	=> {
	return(<>{ format( new Date( t * 1000 ), "yyyy/MM/dd" )}</> );
} ;


export	default	DateCell ;
