/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: EndDateCell.js 173 2024-05-11 05:12:03Z core $
 */


import	{ format		}	from	"date-fns" ;

import	DateCell			from	'../parts/DateCell' ;


/*
 *	@param	t		epoch time.
 */
const	EndDateCell	= ( t, startdate )	=> {
	if( t <= startdate )
		return( <></> ) ;
	return( DateCell( t )) ;
} ;


export	default	EndDateCell ;
