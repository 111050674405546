/*
 *	Copyright 2022 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: WriteMethodEditPage.js 148 2024-05-08 06:19:43Z core $
 */


import	{ useParams	}				from	'react-router-dom' ;
import	WriteMethodEditParts		from	'../parts/WriteMethodEditParts' ;

import	Footer						from	'../parts/Footer' ;
import	Header						from	'../parts/Header' ;


/*
 *	- params.id		writemethod id.
 */
const	WriteMethodEditPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>Edit WriteMethod</h1>
			<WriteMethodEditParts
				id={params.id}
			/>
			<Footer />
		</>
	) ;
} ;

export	default	WriteMethodEditPage ;
