/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: NotFoundPage.js 81 2024-04-20 17:18:28Z core $
 */


import { Link }		from	"react-router-dom" ;

import	Footer		from	'../parts/Footer' ;
import	Header		from	'../parts/Header' ;


const	NotFoundPage	= () => {
	return(
		<>
			<Header />
			<h1>NotFound</h1>
			<Footer />
		</>
	) ;
} ;


export	default	NotFoundPage ;
