/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: InterfaceNewPage.js 143 2024-05-07 02:52:51Z core $
 */


import	axios						from	'axios' ;
import	{ Navigate	}				from	'react-router-dom' ;
import	InterfaceEditParts			from	'../parts/InterfaceEditParts' ;

import	Footer						from	'../parts/Footer' ;
import	Header						from	'../parts/Header' ;


const	InterfaceNewPage	= ()	=> {

	return(
		<>
			<Header />
			<h1>New Interface</h1>
			<InterfaceEditParts />
			<Footer />
		</>
	) ;
} ;


export	default	InterfaceNewPage ;
