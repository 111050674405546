/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: ProductSelectDialog.js 93 2024-04-21 13:21:31Z core $
 */


import	{ useState	}	from	'react' ;
import	Modal			from	'react-modal' ;
import	ListParts		from	'../parts/ProductListParts' ;


/*
 *	- props.productData
 *	- props.onSelectedProduct( id, name )
 */
const	SelectDialog	= ( props )	=> {

	const	[ modalIsOpen,	setIsOpen		]	= useState( false ) ;
	const	[ productData,	setData			]	= useState( props.productData ) ;
	const	[ initialized,	setInitialized	]	= useState( false ) ;

	Modal.setAppElement( '#root' ) ;

	if( !initialized && props.productData.id !== undefined ) {
		if( props.productData.id !== null ) {
			setData( props.productData ) ;
			setInitialized( true ) ;
		}
	}

	const	onSelectedProduct	= ( id, name )	=> {
		setIsOpen( false ) ;
		if( typeof( props.onSelectedProduct ) === 'function' )
			props.onSelectedProduct( id, name ) ;
		setData({
			id		: id,
			name	: name,
		}) ;
	} ;


	return(
	  <div>
		<input
		  type		= 'text'
		  className	= 'form-control'
		  value		= { productData.name }
		  name		= 'productnm'
		  readOnly	= { true }
		  onClick	= {() => setIsOpen( true )}
		/>
		<Modal isOpen={modalIsOpen}
		  style	= {{
			overlay	: {
			  zIndex	: 100,
			},
		  }}
		>
		  <h2>product selector</h2>
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		  <ListParts
			id					= { productData.id }
			onSelectedProduct	= { onSelectedProduct }
		  />
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		</Modal>
	  </div>
	) ;
} ;


export	default	SelectDialog ;
