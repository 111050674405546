/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: MakerNewPage.js 81 2024-04-20 17:18:28Z core $
 */


import	axios						from	'axios' ;
import	{ Navigate	}				from	'react-router-dom' ;
import	MakerEditParts				from	'../parts/MakerEditParts' ;

import	Footer						from	'../parts/Footer' ;
import	Header						from	'../parts/Header' ;


const	MakerNewPage	= ()	=> {

	return(
		<>
			<Header />
			<h1>New Maker</h1>
			<MakerEditParts />
			<Footer />
		</>
	) ;
} ;


export	default	MakerNewPage ;
