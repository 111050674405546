/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: InterfaceEditParts.js 143 2024-05-07 02:52:51Z core $
 */


import	{ useEffect, useState	}	from	'react' ;
import	InterfaceService				from	'../services/InterfaceService' ;


/**
 *	@param	props.id
 */
const	InterfaceEditParts	= ( props )	=> {

	/* ----- 初期値定義 ----- */
	const	initialData		= {
		id:				props.id,
		name:			'',
	} ;

	/* ----- state 定義 ----- */
	const	[ interfaceData,	setInterfaceData	]	= useState( initialData ) ;
	const	[ submitted,	setSubmitted	]	= useState( false ) ;
	const	[ gotData,		setGotData		]	= useState( false ) ;

	/* ----- hook effect event ----- */
	useEffect(() => {

		/* ----- 新規作成か取得済なら再取得しない ----- */
		if( interfaceData.id !== undefined && !gotData ) {

			/* ----- get interface data ----- */
			InterfaceService.get( interfaceData.id )
			.then(( response ) => {
				setGotData( true ) ;
				setInterfaceData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log( e ) ;
			}) ;
		}
	}) ;


	const	handleInputChange	= event =>	{
		const	{ name,	value	}	= event.target ;
		setInterfaceData({ ...interfaceData, [name]: value }) ;
	} ;


	const	onSubmitInterfaceData	= ()	=> {

		let		promise ;
		if( interfaceData.id === undefined )
			promise		= InterfaceService.create( interfaceData ) ;
		else
			promise		= InterfaceService.update( interfaceData.id, interfaceData ) ;

		promise
		.then( response => {
			setInterfaceData({
				id:				response.data.id,
				name:			response.data.name,
			}) ;

			setSubmitted( true ) ;
			console.log( response.data ) ;
		})
		.catch( e => {
			console.log(e);
		}) ;
	} ;

	const	onInterfaceNew	= ()	=> {
		setInterfaceData( initialData ) ;
		setSubmitted( false ) ;
	} ;

	/* ----- コンテンツ返却 ----- */
	return(
        <div className="submit-form">
          {submitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={onInterfaceNew}>
                Add
              </button>
            </div>
          ) : (
            <div>

              <div className="form-group">
                <label htmlFor="name">name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  required
                  value={interfaceData.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>

              <button onClick={onSubmitInterfaceData} className="btn btn-success">
                Submit
              </button>
            </div>
          )}
        </div>
	) ;
} ;


export	default	InterfaceEditParts ;
