/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: Header.js 152 2024-05-09 06:54:21Z core $
 */


import	Button						from '@mui/material/Button' ;
import	{ Link	}					from 'react-router-dom' ;
import	{ useOidc }					from '@axa-fr/react-oidc' ;


/*
 *	- props.pathname
 */
const	Header		= ( props )	=> {

	const	{
		login,				// ログイン関数(プロミスを返す)
		logout,				// ログアウト関数(プロミスを返す)
		renewTokens,		// 更新トークン関数(プロミスを返す)
		isAuthenticated,	// 認証済みフラグ
	}	= useOidc() ;

	return(
	  <div className="Header">

		<Button
		  variant="text"
		  component={Link} to='/storages'
		>STORAGES</Button>

		<Button
		  variant="text"
		  component={Link} to='/products'
		>PRODUCTS</Button>

		<Button
		  variant="text"
		  component={Link} to='/models'
		>MODELS</Button>

		<Button
		  variant="text"
		  component={Link} to='/makers'
		>MAKERS</Button>

		<Button
		  variant="text"
		  component={Link} to='/interfaces'
		>INTERFACES</Button>

		<Button
		  variant="text"
		  component={Link} to='/writemethods'
		>WRITEMETHODS</Button>

		<Button
		  variant="text"
		  component={Link} to='/states'
		>STATES</Button>

		<Button
		  variant="text"
		  component={Link} to='/failures'
		>FAILURES</Button>

		<Button
		  variant="text"
		  component={Link} to='/test'
		>TEST</Button>

		{!isAuthenticated && (
		  <button
			type="button"
			className="btn btn-primary"
			onClick={() => login("/profile")}
		  >Login</button>
		)}

		{isAuthenticated && (
		  <button
			type="button"
			className="btn btn-primary"
			onClick={() => logout("/profile")}
		  >Logout</button>
		)}

		{isAuthenticated && (
		  <button
			type="button"
			className="btn btn-primary"
			onClick={() => renewTokens()}
		  >renewTokens</button>
		)}

	  </div>
	) ;
} ;


export	default	Header ;
