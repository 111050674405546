/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: InterfaceSelectDialog.js 143 2024-05-07 02:52:51Z core $
 */


import	{ useState	}	from	'react' ;
import	Modal			from	'react-modal' ;
import	InterfaceListParts	from	'../parts/InterfaceListParts' ;


/*
 *	- props.interfaceData
 *	- props.onSelectedInterface( id, name )
 */
const	InterfaceSelectDialog	= ( props )	=> {

	const	[ modalIsOpen,		setIsOpen			]	= useState( false ) ;
	const	[ interfaceData,	setInterfaceData	]	= useState( props.interfaceData ) ;
	const	[ initialized,	setInitialized	]	= useState( false ) ;

	Modal.setAppElement( '#root' ) ;

	if( !initialized && props.interfaceData.id !== undefined ) {
		if( props.interfaceData.id !== null ) {
			setInterfaceData( props.interfaceData ) ;
			setInitialized( true ) ;
		}
	}

	const	onSelectedInterface	= ( id, name )	=> {
		setIsOpen( false ) ;
		if( typeof( props.onSelectedInterface ) === 'function' )
			props.onSelectedInterface( id, name ) ;
		setInterfaceData({
			id		: id,
			name	: name,
		}) ;
	} ;


	return(
	  <div>
		<input
		  type		= 'text'
		  className	= 'form-control'
		  value		= { interfaceData.name }
		  name		= 'interfacename'
		  readOnly	= { true }
		  onClick	= {() => setIsOpen( true )}
		/>
		<Modal isOpen={modalIsOpen}
		  style	= {{
			overlay	: {
			  zIndex	: 100,
			},
		  }}
		>
		  <h2>interface selector</h2>
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		  <InterfaceListParts
			id				={ interfaceData.id }
			onSelectedInterface	={ onSelectedInterface }
		  />
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		</Modal>
	  </div>
	) ;
} ;


export	default	InterfaceSelectDialog ;
