/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StorageListParts.js 173 2024-05-11 05:12:03Z core $
 */


import	{ useEffect, useMemo, useRef, useState }	from "react";
import	{ FaRegEdit, FaRegTrashAlt	}				from "react-icons/fa" ;
import	{ useNavigate			}					from "react-router";
import	{ useTable, useRowSelect, useSortBy	}		from "react-table";

import	CapacityCell				from "../parts/CapacityCell" ;
import	StartDateCell				from "../parts/StartDateCell" ;
import	EndDateCell					from "../parts/EndDateCell" ;
import	FailureSelectDialog			from "../dialogs/FailureSelectDialog" ;
import	StorageService				from "../services/StorageService" ;


/*
 *	reference:
 *	- props.id				storage id. selected at initialize.
 *	- props.onSelectedStorage	callback function when selected storage.
 */
const	StorageListParts	= ( props )	=> {

	let	navigate	= useNavigate() ;

	const	[ storageData, 	setStorageData		]	= useState( [] ) ;
	const	[ searchText,	setSearchText		]	= useState( '' ) ;

	const	storageDataRef	= useRef() ;

	storageDataRef.current	= storageData ;
/* --------------------------------------------------------------------*/

  useEffect(() => {
	retrieveStorageData();
  }, []);

  const retrieveStorageData = () => {
	StorageService.getAll()
	  .then((response) => {
		setStorageData(response.data);
	  })
	  .catch((e) => {
		console.log(e);
	  });
  };
/* --------------------------------------------------------------------*/


	const	onChangeSearchText	= ( e )	=> {
		const	searchText	= e.target.value ;
		setSearchText( searchText ) ;
		findByText( searchText ) ;
	} ;


	const	onClearSearchText	= ()	=> {
		setSearchText( '' ) ;
		findByText( '' )
	} ;


	const	findByText	= ( q ) => {
		StorageService.findByText( q )
			.then(( response )	=> {
				setStorageData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log( e ) ;
			}) ;
	} ;


	const	onOpenStorageData	= ( rowIndex )	=> {
		const	id	= storageDataRef.current[ rowIndex ].id ;

		navigate( '/storages/' + id ) ;
	} ;


	const	onDeleteStorageData	= ( rowIndex )	=> {
		const	id	= storageDataRef.current[ rowIndex ].id ;

		StorageService.remove( id )
		.then((response) => {

			let	newStorageData	= [ ...storageDataRef.current ] ;
			newStorageData.splice( rowIndex, 1 ) ;

			setStorageData( newStorageData ) ;
		})
		.catch(( e )	=> {
			console.log(e);
		}) ;
	} ;


	const	columns	= useMemo(()	=> [
		{	Header:	"メーカー",			accessor:"makernm",			},
		{	Header:	"モデル名",			accessor:"modelnm",			},
		{	Header:	"型番/製品名",		accessor:"productnm",		},
		{	Header:	"I/F",				accessor:"interfacenm",		},
		{	Header:	"容量",				accessor:"capacity",
			Cell:	( props )	=> CapacityCell( props ),			},
		{	Header: "書込方式",     	accessor:"writemethodnm",	},
		{	Header:	"Serial Number",	accessor:"sn",				},
		{	Header:	"label",			accessor:"label",			},
		{	Header:	"起動日",			accessor:"startdate",
			Cell:	( props )	=> StartDateCell( props.value ),
		},
		{	Header:	"停止日",			accessor:"enddate",
			Cell:	( props )	=> EndDateCell( props.value,
												props.row.original.startdate ),
		},
		{	Header:	"状態",				accessor:"statenm",			},
		{	Header:	"障害",				accessor:"nfailures",
			Cell:	( props )	=> {
				const	rowIdx		= props.row.id ;
				const	storageid	= props.row.original.id ;
				return(
					<>
					  <FailureSelectDialog
						storageid={storageid} value={props.value} />
					</>
				) ;
			},
		},
		{	Header:	"Actions",			accessor:"actions",
			Cell:	( props )	=> {
				const	rowIdx	= props.row.id ;
				return (
					<div>
					  <span onClick={() => onOpenStorageData( rowIdx )}>
						<FaRegEdit />
					  </span>

					  <span onClick={() => onDeleteStorageData( rowIdx )}>
						<FaRegTrashAlt />
					  </span>
					</div>
				) ;
			},
		},
	],
	[]
	) ;


	const	{
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		toggleAllRowsSelected,
	} = useTable({
			columns,
			data:		storageData,
			initialState :	{
				sortBy :	[
					{
						id:		"startdate",
						desc:	false,
					},
				]
			},
		},
		useSortBy,
		useRowSelect,
	) ;


	return(
/* --------------------------------------------------------------------*/
	<div className="list row">
	  <div className="col-md-8">
		<div className="input-group mb-3">
		  <input
			type="text"
			className="form-control"
			placeholder="Search by text"
			value={searchText}
			onChange={onChangeSearchText}
		  />
		  <div className="input-group-append">
			<button
			  className="btn btn-outline-secondary"
			  type="button"
			  onClick={onClearSearchText}
			>
			  Clear
			</button>
		  </div>
		</div>
	  </div>

	  <div className="col-md-12 list">
		<table
		  className="table table-striped table-bordered"
		  {...getTableProps()}
		>
		  <thead>
			{headerGroups.map((headerGroup) => (
			  <tr {...headerGroup.getHeaderGroupProps()}>
				{headerGroup.headers.map((column) => (
				  <th {...column.getHeaderProps(
					column.getHeaderProps( column.getSortByToggleProps()))}>
					{column.render("Header")}
					<span>
					  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
					</span>
				  </th>
				))}
			  </tr>
			))}
		  </thead>
		  <tbody {...getTableBodyProps()}>
			{rows.map((row, i) => {
			  prepareRow(row);
			  if( props.id === row.original.id && !row.isSelected )
				row.toggleRowSelected() ;
			  return (
				<tr {...row.getRowProps({
					style: {
						backgroundColor: row.isSelected ? 'green' : '',
					},
					onClick:	e => {
						toggleAllRowsSelected( false )
						row.toggleRowSelected()
						if( typeof( props.onSelectedStorage ) === 'function' )
							props.onSelectedStorage( row.original.id,
												  row.original.name ) ;
					},
				})}>

				  {row.cells.map((cell) => {
					return (
					  <td {...cell.getCellProps()}>
						{cell.render("Cell")}
					  </td>
					);
				  })}
				</tr>
			  );
			})}
		  </tbody>
		</table>
	  </div>
	</div>
/* --------------------------------------------------------------------*/
	) ;
} ;


export	default	StorageListParts ;
