/*
 *	Copyright 2022 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: MakerEditPage.js 81 2024-04-20 17:18:28Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	MakerEditParts					from	'../parts/MakerEditParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		maker id.
 */
const	MakerEditPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>Edit Maker</h1>
			<MakerEditParts
				id={params.id}
			/>
			<Footer />
		</>
	) ;
} ;

export	default	MakerEditPage ;
