/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: ModelEditPage.js 85 2024-04-21 08:03:49Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	ModelEditParts					from	'../parts/ModelEditParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		model id.
 */
const	ModelEditPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>Edit Model</h1>
			<ModelEditParts
				id={params.id}
			/>
			<Footer />
		</>
	) ;
} ;

export	default	ModelEditPage ;
