/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StateEditParts.js 123 2024-04-23 15:06:54Z core $
 */


import	{ useEffect, useState	}	from	'react' ;
import	StateService				from	'../services/StateService' ;


/**
 *	@param	props.id
 */
const	StateEditParts	= ( props )	=> {

	/* ----- 初期値定義 ----- */
	const	initialData		= {
		id:				props.id,
		name:			'',
	} ;

	/* ----- state 定義 ----- */
	const	[ stateData,	setStateData	]	= useState( initialData ) ;
	const	[ submitted,	setSubmitted	]	= useState( false ) ;
	const	[ gotData,		setGotData		]	= useState( false ) ;

	/* ----- hook effect event ----- */
	useEffect(() => {

		/* ----- 新規作成か取得済なら再取得しない ----- */
		if( stateData.id !== undefined && !gotData ) {

			/* ----- get state data ----- */
			StateService.get( stateData.id )
			.then(( response ) => {
				setGotData( true ) ;
				setStateData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log( e ) ;
			}) ;
		}
	}) ;


	const	handleInputChange	= event =>	{
		const	{ name,	value	}	= event.target ;
		setStateData({ ...stateData, [name]: value }) ;
	} ;


	const	onSubmitStateData	= ()	=> {

		let		promise ;
		if( stateData.id === undefined )
			promise		= StateService.create( stateData ) ;
		else
			promise		= StateService.update( stateData.id, stateData ) ;

		promise
		.then( response => {
			setStateData({
				id:				response.data.id,
				name:			response.data.name,
			}) ;

			setSubmitted( true ) ;
			console.log( response.data ) ;
		})
		.catch( e => {
			console.log(e);
		}) ;
	} ;

	const	onStateNew	= ()	=> {
		setStateData( initialData ) ;
		setSubmitted( false ) ;
	} ;

	/* ----- コンテンツ返却 ----- */
	return(
        <div className="submit-form">
          {submitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={onStateNew}>
                Add
              </button>
            </div>
          ) : (
            <div>

              <div className="form-group">
                <label htmlFor="name">name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  required
                  value={stateData.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>

              <button onClick={onSubmitStateData} className="btn btn-success">
                Submit
              </button>
            </div>
          )}
        </div>
	) ;
} ;


export	default	StateEditParts ;
