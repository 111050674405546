/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: Footer.js 44 2024-03-15 16:51:12Z core $
 */


const	Footer		= ( props )	=> {

	return(
		<div style={{ textAlign: "center", marginTop: 30 }}>
		  <div>
			copyright 2024 &copy; Shin-ichi Nagamura.
		  </div>
		  <div>
			All rights reserved.
		  </div>
		</div>
	) ;
} ;


export	default	Footer ;
