/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: MakerEditParts.js 81 2024-04-20 17:18:28Z core $
 */


import	{ useEffect, useState	}	from	'react' ;
import	MakerService				from	'../services/MakerService' ;


/**
 *	@param	props.id
 */
const	MakerEditParts	= ( props )	=> {

	/* ----- 初期値定義 ----- */
	const	initialData		= {
		id:				props.id,
		name:			'',
	} ;

	/* ----- state 定義 ----- */
	const	[ makerData,	setMakerData	]	= useState( initialData ) ;
	const	[ submitted,	setSubmitted	]	= useState( false ) ;
	const	[ gotData,		setGotData		]	= useState( false ) ;

	/* ----- hook effect event ----- */
	useEffect(() => {

		/* ----- 新規作成か取得済なら再取得しない ----- */
		if( makerData.id !== undefined && !gotData ) {

			/* ----- get maker data ----- */
			MakerService.get( makerData.id )
			.then(( response ) => {
				setGotData( true ) ;
				setMakerData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log( e ) ;
			}) ;
		}
	}) ;


	const	handleInputChange	= event =>	{
		const	{ name,	value	}	= event.target ;
		setMakerData({ ...makerData, [name]: value }) ;
	} ;


	const	onSubmitMakerData	= ()	=> {

		let		promise ;
		if( makerData.id === undefined )
			promise		= MakerService.create( makerData ) ;
		else
			promise		= MakerService.update( makerData.id, makerData ) ;

		promise
		.then( response => {
			setMakerData({
				id:				response.data.id,
				name:			response.data.name,
			}) ;

			setSubmitted( true ) ;
			console.log( response.data ) ;
		})
		.catch( e => {
			console.log(e);
		}) ;
	} ;

	const	onMakerNew	= ()	=> {
		setMakerData( initialData ) ;
		setSubmitted( false ) ;
	} ;

	/* ----- コンテンツ返却 ----- */
	return(
        <div className="submit-form">
          {submitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={onMakerNew}>
                Add
              </button>
            </div>
          ) : (
            <div>

              <div className="form-group">
                <label htmlFor="name">name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  required
                  value={makerData.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>

              <button onClick={onSubmitMakerData} className="btn btn-success">
                Submit
              </button>
            </div>
          )}
        </div>
	) ;
} ;


export	default	MakerEditParts ;
