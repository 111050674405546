/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StorageListPage.js 91 2024-04-21 10:11:04Z core $
 */


import	{ Link, Navigate		}	from	"react-router-dom" ;
import	{ useOidcAccessToken	}	from	"@axa-fr/react-oidc" ;
import	{ useOidcIdToken		}	from	"@axa-fr/react-oidc" ;

import	{ axiosSetBearerToken	}	from	'../plugins/axios' ;
import	StorageListParts			from	'../parts/StorageListParts' ;
import	Footer						from	"../parts/Footer" ;
import	Header						from	"../parts/Header" ;

import	'../storageledger.css' ;


const	StorageListPage	= () => {
	const	{ accessToken, accessTokenPayload }	= useOidcAccessToken() ;
	const	{ idToken, idTokenPayload	}		= useOidcIdToken() ;

	axiosSetBearerToken( accessToken ) ;

	if(( accessToken || idToken ) && 0 ) {
		/*
		 *	OpenID Connect通信のデバッグ用にaccess token, id token を表示する。
		 *	これのデバッグ終了時にはreturn()を削除して、その下の
		 *	storage listが表示されるようにする。
		 */
		return(
		  <>
		    <Header />
			<h5>Access Token</h5>
			<p>{ JSON.stringify( accessToken )}</p>
			<h5>Access Token payload</h5>
			<p>{ JSON.stringify( accessTokenPayload )}</p>

			<h5>ID Token</h5>
			<p>{ JSON.stringify( idToken )}</p>
			<h5>type of ID Token</h5>
			<p>{ typeof( idToken )}</p>
			<h5>ID Token payload</h5>
			<p>{ JSON.stringify( idTokenPayload )}</p>
		  </>
		) ;
	}

	return(
		<>
		  <Header />
		  <h1>Storage List</h1>
		  <p><Link to={'/storages/new'}>new storage</Link></p>
		  <StorageListParts />
		  <Footer />
		</>
	) ;
} ;


export	default	StorageListPage ;
