/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: FailureSelectDialog.js 169 2024-05-11 02:50:58Z core $
 */


import	{ Link					}		from	'react-router-dom' ;
import	{ useEffect, useState	}		from	'react' ;
import	Modal							from	'react-modal' ;

import	Badge							from	'../parts/Badge' ;
import	FailureListParts				from	'../parts/FailureListParts' ;
import	StorageService					from	'../services/StorageService' ;


/*
 *	- props.storageid
 *	- props.value
 */
const	FailureSelectDialog	= ( props )	=> {

	const	[ storageData,		setStorageData		]	= useState({
		id:			props.storageid,
		makernm:	'',
		modelnm:	'',
		productnm:	'',
	}) ;
	const	[ modalIsOpen,	setIsOpen		]	= useState( false ) ;

	Modal.setAppElement( '#root' ) ;

	const	bg		= ( 0 < props.value ) ? 'danger' : 'primary' ;


	useEffect(()	=> {
	}) ;


	const	openDialog	= ()	=> {
		setIsOpen( true ) ;
		StorageService.get( props.storageid )
			.then(( response )	=> {
				setStorageData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log( e ) ;
			}) ;
	} ;


	return(
	  <div>
		<span onClick = {() => openDialog()}>
		  <Badge bg={bg}>
			{props.value}
		  </Badge>
		</span>

		<Modal isOpen={modalIsOpen}
		  style	= {{
			overlay	: {
			  zIndex	: 100,
			},
		  }}
		>

		  <h2>failure selector</h2>
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		  <h3>{storageData.makernm} / {storageData.modelnm} / {storageData.productnm} / {storageData.label}</h3>
		  <div><Link to={'/failures/new/' + props.storageid }>new failure</Link></div>
		  <FailureListParts
			storageid={props.storageid}
		  />
		  <button onClick={() => setIsOpen( false )}>Cancel</button>

		</Modal>
	  </div>
	) ;
} ;


export	default	FailureSelectDialog ;
