/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StorageEditParts.js 125 2024-04-23 15:40:24Z core $
 */


import	{ useEffect, useState	}	from	'react' ;
import	DatePicker					from	'react-datepicker' ;
import	"react-datepicker/dist/react-datepicker.css"

import	StorageService				from	'../services/StorageService' ;
import	ProductSelectDialog			from	'../dialogs/ProductSelectDialog' ;
import	StateSelectDialog			from	'../dialogs/StateSelectDialog' ;


/**
 *	@param	props.id
 */
const	StorageEditParts	= ( props )	=> {

	/* ----- 初期値定義 ----- */
	const	initialData		= {
		id:				props.id,
		productid:		null,
		sn:				'',
		label:			'',
		stateid:		null,
		startdate:		null,	// unix time
		enddate:		null,	// unix time
	} ;

	/* ----- state 定義 ----- */
	const	[ storageData,	setStorageData	]	= useState( initialData ) ;
	const	[ submitted,	setSubmitted	]	= useState( false ) ;
	const	[ gotData,		setGotData		]	= useState( false ) ;
	const	[ startDate,	setStartDate	]	= useState( new Date())
	const	[ endDate,		setEndDate		]	= useState( new Date())

	/* ----- hook effect event ----- */
	useEffect(() => {

		/* ----- 新規作成か取得済なら再取得しない ----- */
		if( storageData.id !== undefined && !gotData ) {

			/* ----- get storage data ----- */
			StorageService.get( storageData.id )
			.then(( response ) => {

				setGotData( true ) ;
				setStorageData( response.data ) ;
				setStartDate( new Date( response.data.startdate * 1000 )) ;
				setEndDate( new Date( response.data.enddate * 1000 )) ; 
			})
			.catch(( e )	=> {
				console.log( e ) ;
			}) ;
		}
	}) ;


	const	handleInputChange	= event =>	{
		const	{ name,	value	}	= event.target ;
		setStorageData({ ...storageData, [name]: value }) ;
	} ;


	// @param	date	Date class.
	const	setMultiStartDate		= ( date )	=> {
		setStorageData({ ...storageData,
			startdate :	Math.floor( date.getTime() / 1000 ),
		}) ;
		setStartDate( date ) ;
	} ;


	// @param	date	Date class.
	const	setMultiEndDate		= ( date )	=> {
		setStorageData({ ...storageData,
			enddate :	Math.floor( date.getTime() / 1000 ),
		}) ;
		setEndDate( date ) ;
	} ;


	const	onSubmitStorageData	= ()	=> {

		setStorageData({ ...storageData,
			startdate:	Math.floor( startDate.getTime() / 1000 ),
			enddate:	Math.floor( endDate.getTime() / 1000 ),
		})

		let		promise ;
		if( storageData.id === undefined )
			promise		= StorageService.create( storageData ) ;
		else
			promise		= StorageService.update( storageData.id, storageData ) ;

		promise
		.then( response => {
			setStorageData({
				id:				response.data.id,
				pdoductid:		response.data.productid,
				sn:				response.data.sn,
				label:			response.data.label,
				startdate:		response.data.startdate,
				enddate:		response.data.enddate,
				stateid:		response.data.stateid,
			}) ;
			setStartDate( new Date( storageData.startdate )) ;
			setEndDate( new Date( storageData.enddate )) ;

			setSubmitted( true ) ;
			console.log( response.data ) ;
		})
		.catch( e => {
			console.log(e);
		}) ;
	} ;


	const	onStorageNew	= ()	=> {
		setStorageData( initialData ) ;
		setSubmitted( false ) ;
	} ;


	const	onSelectedProduct	= ( id, name )	=> {
		setStorageData({
			...storageData,
				productid	: id,
				productnm	: name,
		}) ;
	}


	const	onSelectedState		= ( id, name )	=> {
		setStorageData({
			...storageData,
				stateid	:	 id,
				statenm	: 	name,
		}) ;
	}


	/* ----- コンテンツ返却 ----- */
	return(
        <div className="submit-form">
          {submitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={onStorageNew}>
                Add
              </button>
            </div>
          ) : (
            <div>

			  <div className="form-group">
				<label htmlFor="productnm">product</label>
				<ProductSelectDialog
				  productData	= {{ id		: storageData.productid,
									 name	: storageData.productnm		}}
				  onSelectedProduct   = { onSelectedProduct } />
			  </div>

              <div className="form-group">
                <label htmlFor="sn">serial number</label>
                <input
                  type="text"
                  className="form-control"
                  id="sn"
                  required
                  value={storageData.sn}
                  onChange={handleInputChange}
                  name="sn"
                />
              </div>

              <div className="form-group">
                <label htmlFor="label">label</label>
                <input
                  type="text"
                  className="form-control"
                  id="label"
                  required
                  value={storageData.label}
                  onChange={handleInputChange}
                  name="label"
                />
              </div>

              <div className="form-group">
                <label htmlFor="startdate">start date</label>
				<div>
				  <DatePicker showicon
					dateFormat="yyy/MM/dd"
					selected={ startDate }
					onChange={( date ) => { setMultiStartDate( date )}}
                  />
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="enddate">end date</label>
				<div>
				  <DatePicker showicon
					dateFormat="yyy/MM/dd"
					selected={ endDate }
					onChange={( date ) => { setMultiEndDate( date )}}
                  />
                </div>
              </div>

			  <div className="form-group">
				<label htmlFor="statenm">state</label>
				<StateSelectDialog
core
				  stateData		= {{ id		: storageData.stateid,
									 name	: storageData.statenm		}}
				  onSelectedState   = { onSelectedState } />
			  </div>

              <button onClick={onSubmitStorageData} className="btn btn-success">
                Submit
              </button>
            </div>
          )}
        </div>
	) ;
} ;


export	default	StorageEditParts ;
