/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: MakerListParts.js 145 2024-05-07 23:39:06Z core $
 */


import	{ useEffect, useMemo, useRef, useState }	from "react";
import	{ useNavigate			}					from "react-router";
import	{ useTable, useRowSelect, useSortBy	}		from "react-table";

import	MakerService		from "../services/MakerService" ;


/*
 *	reference:
 *	- props.id				maker id. selected at initialize.
 *	- props.onSelectedMaker	callback function when selected maker.
 */
const	MakerListParts	= ( props )	=> {

	let	navigate	= useNavigate() ;

	const	[ makerData, 	setMakerData		]	= useState( [] ) ;
	const	[ searchText,	setSearchText		]	= useState( '' ) ;

	const	makerDataRef	= useRef() ;

	makerDataRef.current	= makerData ;

	useEffect(() => {
		retrieveMakerData();
	}, [] ) ;


	const	retrieveMakerData	= () => {
		MakerService.getAll()
			.then(( response ) => {
				setMakerData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log(e);
			}) ;
	} ;


	const	onChangeSearchText	= ( e ) => {
		const	searchText	= e.target.value ;
		setSearchText( searchText ) ;
		findByText( searchText ) ;
	} ;


	const	onClearSearchText	= ()	=> {
		setSearchText( '' ) ;
		findByText( '' )
	} ;


	const	findByText	= ( q ) => {
		MakerService.findByText( q )
			.then(( response )	=> {
				setMakerData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log(e);
			}) ;
	} ;

	const	onOpenMakerData	= ( rowIndex )	=> {
		const	id	= makerDataRef.current[ rowIndex ].id ;

		navigate( '/makers/' + id ) ;
	} ;


	const	onDeleteMakerData	= ( rowIndex )	=> {
		const	id	= makerDataRef.current[ rowIndex ].id ;

		MakerService.remove( id )
		.then((response) => {

			let	newMakerData	= [ ...makerDataRef.current ] ;
			newMakerData.splice( rowIndex, 1 ) ;

			setMakerData( newMakerData ) ;
		})
		.catch(( e )	=> {
			console.log(e);
		}) ;
	} ;


	const	columns	= useMemo(()	=> [
		{	Header:	"名前",			accessor:"name",			},
		{	Header:	"Actions",		accessor:"actions",
			Cell:	( props )	=> {
				const	rowIdx	= props.row.id ;
				return (
					<div>
					  <span onClick={() => onOpenMakerData( rowIdx )}>
						<i className="far fa-edit action mr-2"></i>
					  </span>

					  <span onClick={() => onDeleteMakerData( rowIdx )}>
						<i className="fas fa-trash action"></i>
					  </span>
					</div>
				) ;
			},
		},
	],
	[]
	) ;


	const	{
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		toggleAllRowsSelected,
	} = useTable({
			columns,
			data:		makerData,
			initialState :	{
				sortBy :	[
					{
						id :	"name",
						desc :	false,
					},
				],
			},
		},
		useSortBy,
		useRowSelect,
	) ;


	return(
/* --------------------------------------------------------------------*/
	<div className="list row">
	  <div className="col-md-8">
		<div className="input-group mb-3">
		  <input
			type="text"
			className="form-control"
			placeholder="Search by text"
			value={searchText}
			onChange={onChangeSearchText}
		  />
		  <div className="input-group-append">
			<button
			  className="btn btn-outline-secondary"
			  type="button"
			  onClick={onClearSearchText}
			>
			  Clear
			</button>
		  </div>
		</div>
	  </div>

	  <div className="col-md-12 list">
		<table
		  className="table table-striped table-bordered"
		  {...getTableProps()}
		>
		  <thead>
			{headerGroups.map((headerGroup) => (
			  <tr {...headerGroup.getHeaderGroupProps()}>
				{headerGroup.headers.map((column) => (
				  <th {...column.getHeaderProps(
					column.getHeaderProps( column.getSortByToggleProps()))}>
					{column.render("Header")}
					<span>
					  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
					</span>
				  </th>
				))}
			  </tr>
			))}
		  </thead>
		  <tbody {...getTableBodyProps()}>
			{rows.map((row, i) => {
			  prepareRow(row);
			  if( props.id === row.original.id && !row.isSelected )
				row.toggleRowSelected() ;
			  return (
				<tr {...row.getRowProps({
					style: {
						backgroundColor: row.isSelected ? 'green' : '',
					},
					onClick:	e => {
console.log( "MakerListParts()::onClick():1000" ) ;
						toggleAllRowsSelected( false )
						row.toggleRowSelected()
						if( typeof( props.onSelectedMaker ) === 'function' )
							props.onSelectedMaker( row.original.id,
												  row.original.name ) ;
					},
				})}>

				  {row.cells.map((cell) => {
					return (
					  <td {...cell.getCellProps()}>
						{cell.render("Cell")}
					  </td>
					);
				  })}
				</tr>
			  );
			})}
		  </tbody>
		</table>
	  </div>
	</div>
/* --------------------------------------------------------------------*/
	) ;
} ;


export	default	MakerListParts ;
