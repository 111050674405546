/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StorageEditPage.js 153 2024-05-09 07:51:41Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	StorageEditParts				from	'../parts/StorageEditParts' ;
import	FailureListParts				from	'../parts/FailureListParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		storage id.
 */
const	StorageEditPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>Edit Storage</h1>
			<StorageEditParts
				id={params.id}
			/>
			<h2>Failure List</h2>
			<FailureListParts
				storageid={Number( params.id )}
			/>
			<Footer />
		</>
	) ;
} ;

export	default	StorageEditPage ;
