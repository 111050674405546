/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: MakerListPage.js 81 2024-04-20 17:18:28Z core $
 */


import	{ Link, Navigate		}	from	"react-router-dom" ;

import	MakerListParts				from	'../parts/MakerListParts' ;
import	Footer						from	"../parts/Footer" ;
import	Header						from	"../parts/Header" ;

import	'../storageledger.css' ;


const	MakerListPage	= () => {

	return(
		<>
		  <Header />
		  <h1>Maker List</h1>
		  <p><Link to={'/makers/new'}>new maker</Link></p>
		  <MakerListParts />
		  <Footer />
		</>
	) ;
} ;


export	default	MakerListPage ;
