/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StateListPage.js 123 2024-04-23 15:06:54Z core $
 */


import	{ Link, Navigate		}	from	"react-router-dom" ;

import	StateListParts				from	'../parts/StateListParts' ;
import	Footer						from	"../parts/Footer" ;
import	Header						from	"../parts/Header" ;

import	'../storageledger.css' ;


const	StateListPage	= () => {

	return(
		<>
		  <Header />
		  <h1>State List</h1>
		  <p><Link to={'/states/new'}>new state</Link></p>
		  <StateListParts />
		  <Footer />
		</>
	) ;
} ;


export	default	StateListPage ;
