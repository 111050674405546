/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: MakerSelectDialog.js 85 2024-04-21 08:03:49Z core $
 */


import	{ useState	}	from	'react' ;
import	Modal			from	'react-modal' ;
import	MakerListParts	from	'../parts/MakerListParts' ;


/*
 *	- props.makerData
 *	- props.onSelectedMaker( id, name )
 */
const	MakerSelectDialog	= ( props )	=> {

	const	[ modalIsOpen,	setIsOpen		]	= useState( false ) ;
	const	[ makerData,	setMakerData	]	= useState( props.makerData ) ;
	const	[ initialized,	setInitialized	]	= useState( false ) ;

	Modal.setAppElement( '#root' ) ;

	if( !initialized && props.makerData.id !== undefined ) {
		if( props.makerData.id !== null ) {
			setMakerData( props.makerData ) ;
			setInitialized( true ) ;
		}
	}

	const	onSelectedMaker	= ( id, name )	=> {
		setIsOpen( false ) ;
		if( typeof( props.onSelectedMaker ) === 'function' )
			props.onSelectedMaker( id, name ) ;
		setMakerData({
			id		: id,
			name	: name,
		}) ;
	} ;


	return(
	  <div>
		<input
		  type		= 'text'
		  className	= 'form-control'
		  value		= { makerData.name }
		  name		= 'makername'
		  readOnly	= { true }
		  onClick	= {() => setIsOpen( true )}
		/>
		<Modal isOpen={modalIsOpen}
		  style	= {{
			overlay	: {
			  zIndex	: 100,
			},
		  }}
		>
		  <h2>maker selector</h2>
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		  <MakerListParts
			id				={ makerData.id }
			onSelectedMaker	={ onSelectedMaker }
		  />
		  <button onClick={() => setIsOpen( false )}>Cancel</button>
		</Modal>
	  </div>
	) ;
} ;


export	default	MakerSelectDialog ;
