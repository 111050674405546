/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: ModelListParts.js 145 2024-05-07 23:39:06Z core $
 */


import	{ useEffect, useMemo, useRef, useState }	from "react";
import	{ useNavigate			}					from "react-router";
import	{ useTable, useRowSelect, useSortBy	}		from "react-table";

import	ModelService		from "../services/ModelService" ;


/*
 *	reference:
 *	- props.id				model id. selected at initialize.
 *	- props.onSelectedModel	callback function when selected model.
 */
const	ModelListParts	= ( props )	=> {

	let	navigate	= useNavigate() ;

	const	[ modelData, 	setModelData		]	= useState( [] ) ;
	const	[ searchText,	setSearchText		]	= useState( '' ) ;

	const	modelDataRef	= useRef() ;

	modelDataRef.current	= modelData ;

	useEffect(() => {
		retrieveModelData();
	}, [] ) ;


	const	retrieveModelData	= () => {
		ModelService.getAll()
			.then(( response ) => {
				setModelData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log(e);
			}) ;
	} ;


	const	onChangeSearchText	= ( e ) => {
		const	searchText	= e.target.value ;
		setSearchText( searchText ) ;
		findByText( searchText ) ;
	} ;


	const	onClearSearchText	= ()	=> {
		setSearchText( '' ) ;
		findByText( '' ) ;
	} ;


	const	findByText	= ( q ) => {
		ModelService.findByText( q )
			.then(( response )	=> {
				setModelData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log(e);
			}) ;
	} ;

	const	onOpenModelData	= ( rowIndex )	=> {
		const	id	= modelDataRef.current[ rowIndex ].id ;

		navigate( '/models/' + id ) ;
	} ;


	const	onDeleteModelData	= ( rowIndex )	=> {
		const	id	= modelDataRef.current[ rowIndex ].id ;

		ModelService.remove( id )
		.then((response) => {

			let	newModelData	= [ ...modelDataRef.current ] ;
			newModelData.splice( rowIndex, 1 ) ;

			setModelData( newModelData ) ;
		})
		.catch(( e )	=> {
			console.log(e);
		}) ;
	} ;


	const	columns	= useMemo(()	=> [
		{	Header:	"メーカー名",	accessor:"makernm",			},
		{	Header:	"モデル名",		accessor:"name",			},
		{	Header:	"Actions",		accessor:"actions",
			Cell:	( props )	=> {
				const	rowIdx	= props.row.id ;
				return (
					<div>
					  <span onClick={() => onOpenModelData( rowIdx )}>
						<i className="far fa-edit action mr-2"></i>
					  </span>

					  <span onClick={() => onDeleteModelData( rowIdx )}>
						<i className="fas fa-trash action"></i>
					  </span>
					</div>
				) ;
			},
		},
	],
	[]
	) ;


	const	{
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		toggleAllRowsSelected,
	} = useTable({
			columns,
			data:		modelData,
			initialState :	{
				sortBy :	[
					{
						id :	"makernm",
						desc :	false,
					}
				],
			},
		},
		useSortBy,
		useRowSelect,
	) ;


	return(
/* --------------------------------------------------------------------*/
	<div className="list row">
	  <div className="col-md-8">
		<div className="input-group mb-3">
		  <input
			type="text"
			className="form-control"
			placeholder="Search by text"
			value={searchText}
			onChange={onChangeSearchText}
		  />
		  <div className="input-group-append">
			<button
			  className="btn btn-outline-secondary"
			  type="button"
			  onClick={onClearSearchText}
			>
			  Clear
			</button>
		  </div>
		</div>
	  </div>

	  <div className="col-md-12 list">
		<table
		  className="table table-striped table-bordered"
		  {...getTableProps()}
		>
		  <thead>
			{headerGroups.map((headerGroup) => (
			  <tr {...headerGroup.getHeaderGroupProps()}>
				{headerGroup.headers.map((column) => (
				  <th {...column.getHeaderProps(
					column.getHeaderProps( column.getSortByToggleProps()))}>
					{column.render("Header")}
					<span>
					  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
					</span>
				  </th>
				))}
			  </tr>
			))}
		  </thead>
		  <tbody {...getTableBodyProps()}>
			{rows.map((row, i) => {
			  prepareRow(row);
			  if( props.id === row.original.id && !row.isSelected )
				row.toggleRowSelected() ;
			  return (
				<tr {...row.getRowProps({
					style: {
						backgroundColor: row.isSelected ? 'green' : '',
					},
					onClick:	e => {
						toggleAllRowsSelected( false )
						row.toggleRowSelected()
						if( typeof( props.onSelectedModel ) === 'function' )
							props.onSelectedModel( row.original.id,
												  row.original.name ) ;
					},
				})}>

				  {row.cells.map((cell) => {
					return (
					  <td {...cell.getCellProps()}>
						{cell.render("Cell")}
					  </td>
					);
				  })}
				</tr>
			  );
			})}
		  </tbody>
		</table>
	  </div>
	</div>
/* --------------------------------------------------------------------*/
	) ;
} ;


export	default	ModelListParts ;
