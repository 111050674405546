/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: InterfaceListPage.js 143 2024-05-07 02:52:51Z core $
 */


import	{ Link, Navigate		}	from	"react-router-dom" ;

import	InterfaceListParts			from	'../parts/InterfaceListParts' ;
import	Footer						from	"../parts/Footer" ;
import	Header						from	"../parts/Header" ;

import	'../storageledger.css' ;


const	InterfaceListPage	= () => {

	return(
		<>
		  <Header />
		  <h1>Interface List</h1>
		  <p><Link to={'/interfaces/new'}>new interface</Link></p>
		  <InterfaceListParts />
		  <Footer />
		</>
	) ;
} ;


export	default	InterfaceListPage ;
