/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StorageNewPage.js 93 2024-04-21 13:21:31Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	StorageEditParts				from	'../parts/StorageEditParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		storage id.
 */
const	StorageNewPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>New Storage</h1>
			<StorageEditParts />
			<Footer />
		</>
	) ;
} ;

export	default	StorageNewPage ;
