/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: ProductEditParts.js 148 2024-05-08 06:19:43Z core $
 */


import	{ useEffect, useState	}	from	'react' ;
import	ProductService				from	'../services/ProductService' ;
import	InterfaceSelectDialog		from	'../dialogs/InterfaceSelectDialog' ;
import	ModelSelectDialog			from	'../dialogs/ModelSelectDialog' ;
import	WriteMethodSelectDialog		from	'../dialogs/WriteMethodSelectDialog' ;


/**
 *	@param	props.id
 */
const	ProductEditParts	= ( props )	=> {

	/* ----- 初期値定義 ----- */
	const	initialData		= {
		id:				props.id,
		modelid:		null,
		name:			'',
	} ;

	/* ----- state 定義 ----- */
	const	[ productData,	setProductData	]	= useState( initialData ) ;
	const	[ withUnit,		setWithUnit		]	= useState( '0' ) ;
	const	[ submitted,	setSubmitted	]	= useState( false ) ;
	const	[ gotData,		setGotData		]	= useState( false ) ;


	/*
	 *	入力された数値に単位を付けて文字列として返す。
	 */
	const	addUnit	= ( n )	=> {

		if( n < 1000 )
			return( n ) ;
		n	/= 1000 ;

		if( n < 1000 )
			return( n + ' KB' ) ;
		n	/= 1000 ;

		if( n < 1000 )
			return( n + ' MB' ) ;
		n	/= 1000 ;

		if( n < 1000 )
			return( n + ' GB' ) ;
		n	/= 1000 ;

		if( n < 1000 )
			return( n + ' TB' ) ;
		n	/= 1000 ;

		return( n + ' PB' ) ;
	} ;


	/*
	 *	@brief	単位を外して数値にして返す。
	 *	@param	s	単位付き数値文字列。
	 *				ex) '2TB'
	 */
	const	removeUnit	= ( s )	=> {
		s	= s.trim() ;

		const	m	= s.match( /^([\d\.]+)\s*([KGMT]{0,1})B{0,1}$/ ) ;
		if( !m ) {
			return( s ) ;
		}

		let		num		= m[1] ;
		const	unit	= m[2] ;

		switch( unit ) {
		case	'K' :	num	*= 1000 ;							break ;
		case	'M' :	num	*= 1000 * 1000 ;					break ;
		case	'G' :	num	*= 1000 * 1000 * 1000 ;				break ;
		case	'T' :	num	*= 1000 * 1000 * 1000 * 1000 ;		break ;
		}

		return( parseInt( num )) ;
	} ;


	/* ----- hook effect event ----- */
	useEffect(() => {

		/* ----- 新規作成か取得済なら再取得しない ----- */
		if( productData.id !== undefined && !gotData ) {

			/* ----- get product data ----- */
			ProductService.get( productData.id )
			.then(( response ) => {
				setGotData( true ) ;
				setProductData( response.data ) ;
				setWithUnit({...withUnit,
						capacity: addUnit( response.data.capacity )}) ;
			})
			.catch(( e )	=> {
				console.log( e ) ;
			}) ;
		}
	}) ;


	const	handleInputChange	= event =>	{
		const	{ name,	value	}	= event.target ;
		setProductData({ ...productData, [name]: value }) ;
	} ;


	const	handleUnitInputChange	= event =>	{
		const	{ name,	value	}	= event.target ;
		setProductData({ ...productData, [name]: removeUnit( value )}) ;
		setWithUnit({ ...withUnit, [name]: value }) ;
	} ;


	const	onSubmitProductData	= ()	=> {

		let		promise ;
		if( productData.id === undefined )
			promise		= ProductService.create( productData ) ;
		else
			promise		= ProductService.update( productData.id, productData ) ;

		promise
		.then( response => {
			setProductData({
				id:				response.data.id,
				modelid:		response.data.modelid,
				name:			response.data.name,
			}) ;

			setSubmitted( true ) ;
			console.log( response.data ) ;
		})
		.catch( e => {
			console.log(e);
		}) ;
	} ;


	const	onProductNew	= ()	=> {
		setProductData( initialData ) ;
		setSubmitted( false ) ;
	} ;


	const	onSelectedModel	= ( id, name )	=> {
		setProductData({ ...productData,
			modelid		: id,
			modelnm		: name,
		}) ;
	}


	const	onSelectedInterface	= ( id, name )	=> {
		setProductData({ ...productData,
			interfaceid		: id,
			interfacenm		: name,
		}) ;
	}


	const	onSelectedWriteMethod	= ( id, name )	=> {
		setProductData({ ...productData,
			writemethodid		: id,
			writemethodnm		: name,
		}) ;
	}


	/* ----- コンテンツ返却 ----- */
	return(
        <div className="submit-form">
          {submitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={onProductNew}>
                Add
              </button>
            </div>
          ) : (
            <div>

			  <div className="form-group">
				<label htmlFor="modelnm">model</label>
				<ModelSelectDialog
				  modelData		= {{ id		: productData.modelid,
									 name	: productData.modelnm		}}
				  onSelectedModel   = { onSelectedModel } />
			  </div>

			  <div className="form-group">
				<label htmlFor="interfacenm">interface</label>
				<InterfaceSelectDialog
				  interfaceData		= {{ id		: productData.interfaceid,
										 name	: productData.interfacenm	}}
				  onSelectedInterface   = { onSelectedInterface } />
			  </div>

			  <div className="form-group">
				<label htmlFor="writemethodnm">writemethod</label>
				<WriteMethodSelectDialog
				  writemethodData	= {{ id		: productData.writemethodid,
										 name	: productData.writemethodnm	}}
				  onSelectedWriteMethod   = { onSelectedWriteMethod } />
			  </div>

              <div className="form-group">
                <label htmlFor="name">name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  required
                  value={productData.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="capacity">capacity</label>
                <input
                  type="text"
                  className="form-control"
                  id="capacity"
                  required
                  value={withUnit.capacity}
                  onChange={handleUnitInputChange}
                  name="capacity"
                />
              </div>

              <button onClick={onSubmitProductData} className="btn btn-success">
                Submit
              </button>
            </div>
          )}
        </div>
	) ;
} ;


export	default	ProductEditParts ;
