/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: ModelNewPage.js 94 2024-04-21 13:25:50Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	ModelEditParts					from	'../parts/ModelEditParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		model id.
 */
const	ModelNewPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>New Model</h1>
			<ModelEditParts />
			<Footer />
		</>
	) ;
} ;

export	default	ModelNewPage ;
