/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: TestPage.js 73 2024-04-20 07:40:03Z core $
 */


import	{ useEffect, useState	}	from	"react" ;
import	{ useOidcAccessToken	}	from	"@axa-fr/react-oidc" ;
import	{ useOidcIdToken		}	from	"@axa-fr/react-oidc" ;

import	TestService		from	"../services/TestService" ;

import	Footer			from	"../parts/Footer" ;
import	Header			from	"../parts/Header" ;


const	TestPage = () => {
	const	[ bearerTokenText,	setBearerTokenText	]	= useState( '' ) ;
	const	[ accessTokenText,	setAccessTokenText	]	= useState( '' ) ;
	const	[ userid,			setUserid			]	= useState( '' ) ;
	const	{ accessToken, accessTokenPayload		}	= useOidcAccessToken() ;
	const	{ idToken, idTokenPayload				}	= useOidcIdToken() ;

	useEffect(() => {
		retrieveBearerTokenText() ;
		retrieveAccessTokenText() ;
		retrieveUserid() ;
	}, [] ) ;

	const	retrieveBearerTokenText	= () => {
		TestService.getBearerToken()
		.then(( response ) => {
			setBearerTokenText( response.data ) ;
		})
		.catch(( e ) => {
			console.log( e ) ;
		}) ;
	} ;

	const	retrieveAccessTokenText	= () => {
		TestService.getAccessToken()
		.then(( response ) => {
			setAccessTokenText( JSON.stringify( response.data )) ;
		})
		.catch(( e ) => {
			console.log( e ) ;
		}) ;
	} ;

	const	retrieveUserid	= () => {
		TestService.getUserid()
		.then(( response ) => {
			setUserid( JSON.stringify( response.data )) ;
		})
		.catch(( e ) => {
			console.log( e ) ;
		}) ;
	} ;

	return(
	  <>
		<Header />
		<h1>Test</h1>

		<hr/>
		<h5>Access Token</h5>
		<p>{ JSON.stringify( accessToken )}</p>
		<h5>Access Token payload</h5>
		<p>{ JSON.stringify( accessTokenPayload )}</p>

		<hr/>
		<h5>ID Token</h5>
		<p>{ JSON.stringify( idToken )}</p>
		<h5>type of ID Token</h5>
		<p>{ typeof( idToken )}</p>
		<h5>ID Token payload</h5>
		<p>{ JSON.stringify( idTokenPayload )}</p>

		<h5>bearer token</h5>
		<p>{ bearerTokenText }</p>

		<h5>access token</h5>
		<p>{ accessTokenText }</p>

		<h5>userid</h5>
		<p>{ userid }</p>

		<Footer />
	  </>
	) ;
} ;


export	default	TestPage ;
