/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: CapacityCell.js 144 2024-05-07 10:02:39Z core $
 */


import	{ format		}	from	"date-fns" ;


const	CapacityCell	= ( props )	=> {

	var		cap		= props.value ;

	if( cap < 1000 )
		return(<>{cap + ' B'}</>) ;
	cap		/= 1000 ;

	if( cap < 1000 )
		return(<>{cap + ' KB'}</>) ;
	cap		/= 1000 ;

	if( cap < 1000 )
		return(<>{cap + ' MB'}</>) ;
	cap		/= 1000 ;

	if( cap < 1000 )
		return(<>{cap + ' GB'}</>) ;
	cap		/= 1000 ;

	if( cap < 1000 )
		return(<>{cap + ' TB'}</>) ;
	cap		/= 1000 ;

	return(<>{cap + ' PB'}</>) ;
} ;


export	default	CapacityCell ;
