/*
 *	Copyright 2022 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: InterfaceEditPage.js 143 2024-05-07 02:52:51Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	InterfaceEditParts				from	'../parts/InterfaceEditParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		interface id.
 */
const	InterfaceEditPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>Edit Interface</h1>
			<InterfaceEditParts
				id={params.id}
			/>
			<Footer />
		</>
	) ;
} ;

export	default	InterfaceEditPage ;
