/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: FailureListParts.js 158 2024-05-10 01:43:56Z core $
 */


import	{ useEffect, useMemo, useRef, useState }	from "react";
import	{ useNavigate			}					from "react-router";
import	{ Link					}					from "react-router-dom";
import	{ useTable, useRowSelect, useSortBy	}		from "react-table";

import	DateCell			from "../parts/DateCell" ;
import	FailureService		from "../services/FailureService" ;


/*
 *	reference:
 *	- props.storageid	(optional)なければ全数出力。
 */
const	FailureListParts	= ( props )	=> {

	let	navigate	= useNavigate() ;

	const	[ failureData, 	setFailureData		]	= useState( [] ) ;
	const	[ searchText,	setSearchText		]	= useState( '' ) ;

	const	failureDataRef	= useRef() ;

	failureDataRef.current	= failureData ;

	useEffect(() => {
		if( failureData.length < 1 )
			retrieveFailureData();
	}, [] ) ;


	const	retrieveFailureData	= () => {

		let		promise ;
		if( typeof( props.storageid ) == 'number' )
			promise		= FailureService.findByStorageId( props.storageid )
		else
			promise		= FailureService.getAll()

		promise
			.then(( response ) => {
				setFailureData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log(e);
			}) ;
	} ;


	const	onChangeSearchText	= ( e ) => {
		const	searchText	= e.target.value ;
		setSearchText( searchText ) ;
		findByText( searchText ) ;
	} ;


	const	onClearSearchText	= ()	=> {
		setSearchText( '' ) ;
		findByText( '' ) ;
	} ;


	const	findByText	= ( q ) => {
		FailureService.findByText( q )
			.then(( response )	=> {
				setFailureData( response.data ) ;
			})
			.catch(( e )	=> {
				console.log(e);
			}) ;
	} ;

	const	onOpenFailureData	= ( rowIndex )	=> {
		const	id	= failureDataRef.current[ rowIndex ].id ;

		navigate( '/failures/' + id ) ;
	} ;


	const	onDeleteFailureData	= ( rowIndex )	=> {
		const	id	= failureDataRef.current[ rowIndex ].id ;

		FailureService.remove( id )
		.then((response) => {

			let	newFailureData	= [ ...failureDataRef.current ] ;
			newFailureData.splice( rowIndex, 1 ) ;

			setFailureData( newFailureData ) ;
		})
		.catch(( e )	=> {
			console.log(e);
		}) ;
	} ;


	const	columns	= useMemo(()	=> [
		{	Header:	"date",			accessor:"date",
			Cell:	( props )	=> DateCell( props.value ),
		},
		{	Header:	"details",		accessor:"detail",
			Cell:	( props )	=> {
				return(<>
					<span>
					  <Link to={'/storages/' + props.row.original.storageid}>
						{props.row.original.label} / {props.row.original.sn}
					  </Link>
					</span>
					<div>{props.value}</div>
				</>) ;
			},
		},
		{	Header:	"Actions",		accessor:"actions",
			Cell:	( props )	=> {
				const	rowIdx	= props.row.id ;
				return (
					<div>
					  <span onClick={() => onOpenFailureData( rowIdx )}>
						<i className="far fa-edit action mr-2"></i>
					  </span>

					  <span onClick={() => onDeleteFailureData( rowIdx )}>
						<i className="fas fa-trash action"></i>
					  </span>
					</div>
				) ;
			},
		},
	],
	[]
	) ;


	const	{
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		toggleAllRowsSelected,
	} = useTable({
			columns,
			data:		failureData,
			initialState :	{
				sortBy :	[
					{
						id :	"date",
						desc :	false,
					},
				],
			},
		},
		useSortBy,
		useRowSelect,
	) ;


	return(
/* --------------------------------------------------------------------*/
	<div className="list row">
	  <div className="col-md-8">
		<div className="input-group mb-3">
		  <input
			type="text"
			className="form-control"
			placeholder="Search by text"
			value={searchText}
			onChange={onChangeSearchText}
		  />
		  <div className="input-group-append">
			<button
			  className="btn btn-outline-secondary"
			  type="button"
			  onClick={onClearSearchText}
			>
			  Clear
			</button>
		  </div>
		</div>
	  </div>

	  <div className="col-md-12 list">
		<table
		  className="table table-striped table-bordered"
		  {...getTableProps()}
		>
		  <thead>
			{headerGroups.map((headerGroup) => (
			  <tr {...headerGroup.getHeaderGroupProps()}>
				{headerGroup.headers.map((column) => (
				  <th {...column.getHeaderProps(
					column.getHeaderProps( column.getSortByToggleProps()))}>
					{column.render("Header")}
					<span>
					  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
					</span>
				  </th>
				))}
			  </tr>
			))}
		  </thead>
		  <tbody {...getTableBodyProps()}>
			{rows.map((row, i) => {
			  prepareRow(row);
			  if( props.id === row.original.id && !row.isSelected )
				row.toggleRowSelected() ;
			  return (
				<tr {...row.getRowProps({
					style: {
						backgroundColor: row.isSelected ? 'green' : '',
					},
					onClick:	e => {
						toggleAllRowsSelected( false )
						row.toggleRowSelected()
						if( typeof( props.onSelectedFailure ) === 'function' )
							props.onSelectedFailure( row.original.id,
												  row.original.name ) ;
					},
				})}>

				  {row.cells.map((cell) => {
					return (
					  <td {...cell.getCellProps()}>
						{cell.render("Cell")}
					  </td>
					);
				  })}
				</tr>
			  );
			})}
		  </tbody>
		</table>
	  </div>
	</div>
/* --------------------------------------------------------------------*/
	) ;
} ;


export	default	FailureListParts ;
