/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: ProductNewPage.js 90 2024-04-21 10:06:55Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	ProductEditParts				from	'../parts/ProductEditParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		product id.
 */
const	ProductNewPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>New Product</h1>
			<ProductEditParts />
			<Footer />
		</>
	) ;
} ;

export	default	ProductNewPage ;
