/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StartDateCell.js 156 2024-05-09 08:12:28Z core $
 */


import	{ format		}	from	"date-fns" ;

import	DateCell			from	'../parts/DateCell' ;


/*
 *	@param	t		epoch time.
 */
const	StartDateCell	= ( t )	=> {
	return( DateCell( t )) ;
} ;


export	default	StartDateCell ;
