/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: InterfaceService.js 143 2024-05-07 02:52:51Z core $
 */


import	axios	from	'axios' ;


const	url		= "/interfaces"


const	create	= ( data )	=> {
	return( axios.post( url, data )) ;
} ;


const	getAll	= ()	=> {
	return( axios.get( url )) ;
} ;


const	get		= ( id )	=> {
	return( axios.get( url + '/' + id )) ;
} ;


const	update	= ( id, data )	=> {
	return( axios.put( url +  '/' +id, data )) ;
} ;


const	remove	= ( id )	=> {
	return( axios.delete( url +  '/' +id )) ;
} ;


const	findByText	= ( t )		=> {
	return( axios.get( url + '?q=' + t )) ;
} ;


const	InterfaceService	= {
	url,
	create,
	getAll,
	get,
	update,
	remove,
	findByText,
} ;


export  default	InterfaceService ;

