/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: WriteMethodNewPage.js 148 2024-05-08 06:19:43Z core $
 */


import	axios						from	'axios' ;
import	{ Navigate	}				from	'react-router-dom' ;
import	WriteMethodEditParts		from	'../parts/WriteMethodEditParts' ;

import	Footer						from	'../parts/Footer' ;
import	Header						from	'../parts/Header' ;


const	WriteMethodNewPage	= ()	=> {

	return(
		<>
			<Header />
			<h1>New WriteMethod</h1>
			<WriteMethodEditParts />
			<Footer />
		</>
	) ;
} ;


export	default	WriteMethodNewPage ;
