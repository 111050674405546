/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: FailureEditParts.js 207 2024-11-10 05:56:07Z core $
 */


import	{ useEffect, useState	}	from	'react' ;
import	DatePicker					from	'react-datepicker' ;
import	FailureService				from	'../services/FailureService' ;


/**
 *	@param	props.id			(either id or storageid)
 *	@param	props.storageid		(either id or storageid)
 */
const	FailureEditParts	= ( props )	=> {

	/* ----- 初期値定義 ----- */
	const	initialData		= {
		id:				props.id,
		storageid:		props.storageid,
		date:			0,
		detail:			'',
	} ;

	/* ----- state 定義 ----- */
	const	[ failureData,	setFailureData	]	= useState( initialData ) ;
	const	[ submitted,	setSubmitted	]	= useState( false ) ;
	const	[ gotData,		setGotData		]	= useState( false ) ;
	const	[ failureDate,	setFailureDate	]	= useState( new Date())

	/* ----- hook effect event ----- */
	useEffect(() => {

		/* ----- 新規作成 ----- */
		if( failureData.id === undefined ) {
			setFailureData({ ...failureData,
				date:	Math.floor( failureDate.getTime() / 1000 ),
			}) ;

		/* ----- 取得済みなら再取得しない ----- */
		} else if( !gotData ) {

			/* ----- get failure data ----- */
			FailureService.get( failureData.id )
			.then(( response ) => {
				setGotData( true ) ;
				setFailureData( response.data ) ;
				setFailureDate( new Date( response.data.date * 1000 ))
			})
			.catch(( e )	=> {
				console.log( e ) ;
			}) ;
		}
	}) ;


	const	handleInputChange	= event =>	{
		const	{ name,	value	}	= event.target ;
		setFailureData({ ...failureData, [name]: value }) ;
	} ;


	const	onSubmitFailureData	= ()	=> {

		setFailureData({ ...failureData,
			date:	Math.floor( failureDate.getTime() / 1000 ),
		}) ;

		let		promise ;
		if( failureData.id === undefined )
			promise		= FailureService.create( failureData ) ;
		else
			promise		= FailureService.update( failureData.id, failureData ) ;

		promise
		.then( response => {
			setFailureData({
				id:				response.data.id,
				date:			response.data.date,
				detail:			response.data.detail,
			}) ;
			setFailureDate( new Date( failureData.date * 1000 )) ;

			setSubmitted( true ) ;
			console.log( response.data ) ;
		})
		.catch( e => {
			console.log(e);
		}) ;
	} ;

	const	onFailureNew	= ()	=> {
		setFailureData( initialData ) ;
		setSubmitted( false ) ;
	} ;

	/* ----- コンテンツ返却 ----- */
	return(
        <div className="submit-form">
          {submitted ? (
            <div>
              <h4>You submitted successfully!</h4>
              <button className="btn btn-success" onClick={onFailureNew}>
                Add
              </button>
            </div>
          ) : (
            <div>
			  <div className="form-group">
				<label htmlFor="date">date</label>
				<div>
				  <DatePicker showicon
					dateFormat="yyy/MM/dd"
					selected={ failureDate }
					onChange={( date ) => {
						setFailureData({ ...failureData,
							date:	Math.floor( date.getTime() / 1000 )
						 }) ;
						setFailureDate( date ) ;
					}}
				  />
				</div>
			  </div>

              <div className="form-group">
                <label htmlFor="detail">detail</label>
                <input
                  type="text"
                  className="form-control"
                  id="detail"
                  required
                  value={failureData.detail}
                  onChange={handleInputChange}
                  name="detail"
                />
              </div>

              <button onClick={onSubmitFailureData} className="btn btn-success">
                Submit
              </button>
            </div>
          )}
        </div>
	) ;
} ;


export	default	FailureEditParts ;
