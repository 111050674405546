/*
 *	Copyright 2022 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: StateEditPage.js 123 2024-04-23 15:06:54Z core $
 */


import	{ useParams	}					from	'react-router-dom' ;
import	StateEditParts					from	'../parts/StateEditParts' ;

import	Footer							from	'../parts/Footer' ;
import	Header							from	'../parts/Header' ;


/*
 *	- params.id		maker id.
 */
const	StateEditPage	= ()	=> {

	let	params	= useParams() ;


	return(
		<>
			<Header />
			<h1>Edit State</h1>
			<StateEditParts
				id={params.id}
			/>
			<Footer />
		</>
	) ;
} ;

export	default	StateEditPage ;
