/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id$
 */


import	axios					from	'axios' ;


/* ----- set base URL ----- */
axios.defaults.baseURL	= '/api/' ;


export	function	axiosSetBearerToken( token ) {
	if( token )
		axios.defaults.headers.common['Authorization']	= 'bearer ' + token ;
}
