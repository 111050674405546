/*
 *	Copyright 2024 (c) Shin-ichi Nagamura.
 *	All rights reserved.
 *
 *	$Id: ModelListPage.js 87 2024-04-21 09:20:26Z core $
 */


import	{ Link, Navigate		}	from	"react-router-dom" ;

import	ModelListParts				from	'../parts/ModelListParts' ;
import	Footer						from	"../parts/Footer" ;
import	Header						from	"../parts/Header" ;

import	'../storageledger.css' ;


const	ModelListPage	= () => {

	return(
		<>
		  <Header />
		  <h1>Model List</h1>
		  <p><Link to={'/models/new'}>new model</Link></p>
		  <ModelListParts />
		  <Footer />
		</>
	) ;
} ;


export	default	ModelListPage ;
